// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-results {
  display: flex;
  flex-direction: column;
}

.search-results-heading {
  background-color: #fff;
  border-left: solid 1px #c4c4c4;
  border-right: solid 1px #c4c4c4;
  padding-left: 15px;
  padding-top: 10px;
  height: 43px;
}

.search-results-buttons {
  position: absolute;
  right: 20px;
  /* Move the button bar up */
  margin-top: -4px;
}

.top-pager {
  margin-right: 50px;
  margin-top: -4px;
  float: right;
}

.bottom-pager {
  margin-right: 15px;
  margin-top: 12px;
  float: right;
}

.per-page {
  margin-left: 16px;
  margin-top: 11px;
  float: left;
}

.results-pager-row {
  background-color: #ffffff;
  height: 55px;
}

.results-pager-row-scroll {
  background-color: #ffffff;
  height: 55px;
  position: fixed;
  bottom: 10px;
  /** Left side data panel */
  width: calc(100% - 400px);
}

.search-results-footer {
  border: solid 1px #c4c4c4;
}`, "",{"version":3,"sources":["webpack://./src/app/image-search/data-section/data-section-tabs/search-results/search-results.component.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,8BAAA;EACA,+BAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EAEA,2BAAA;EAEA,gBAAA;AADJ;;AAKA;EACI,kBAAA;EACA,gBAAA;EACA,YAAA;AAFJ;;AAKA;EACI,kBAAA;EACA,gBAAA;EACA,YAAA;AAFJ;;AAKA;EACI,iBAAA;EACA,gBAAA;EACA,WAAA;AAFJ;;AAKA;EACI,yBAAA;EACA,YAAA;AAFJ;;AAKA;EACI,yBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EAEA,0BAAA;EACA,yBAAA;AAHJ;;AAMA;EACI,yBAAA;AAHJ","sourcesContent":[".search-results {\n    display: -webkit-flex;\n    display: flex;\n    flex-direction: column;\n}\n\n.search-results-heading {\n    background-color: #fff;\n    border-left: solid 1px #c4c4c4;\n    border-right: solid 1px #c4c4c4;\n    padding-left: 15px;\n    padding-top: 10px;\n    height: 43px;\n}\n\n.search-results-buttons {\n    position: absolute;\n    right: 20px;\n\n    /* Move the button bar up */\n    // margin-top: -48px;\n    margin-top: -4px;\n\n}\n\n.top-pager {\n    margin-right: 50px;\n    margin-top: -4px;\n    float: right;\n}\n\n.bottom-pager {\n    margin-right: 15px;\n    margin-top: 12px;\n    float: right;\n}\n\n.per-page {\n    margin-left: 16px;\n    margin-top: 11px;\n    float: left;\n}\n\n.results-pager-row {\n    background-color: #ffffff;\n    height: 55px;\n}\n\n.results-pager-row-scroll {\n    background-color: #ffffff;\n    height: 55px;\n    position: fixed;\n    bottom: 10px;\n\n    /** Left side data panel */\n    width: calc(100% - 400px);\n}\n\n.search-results-footer {\n    border: solid 1px #c4c4c4;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
